export const searchFormConfig = {
  formItems: [
    {
      field: 'operationType',
      type: 'select',
      label: 'log.operation-type',
      options: []
    },
    {
      field: 'message',
      type: 'input',
      label: 'log.message'
    },
    {
      field: 'ipAddress',
      type: 'input',
      label: 'log.ip-address'
    },
    {
      field: 'location',
      type: 'input',
      label: 'log.location'
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
